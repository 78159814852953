import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, VStack, Text, HStack, Pressable, Link } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { showToast } from "@helpers/toast";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { Modal, Pagination, Space, Table } from "antd";
import SearchBox from "@views/components/ui/search_box/search_box";
import ActionButton from "@views/components/ui/dialog/action_button";
import { showNotification } from "@helpers/notify";
import { FaBuilding } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { startCase } from "lodash";
import AlbumDetails from "./album_details";
import {
  get_all_gallery_image_list,
  mutation_delete_gallery_image,
} from "@services/redux/slices/dynamic_entity/graphql/grahpql_cms_gallery_image";
import { countFormat } from "@helpers/constants";

const AlbumList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form_values, set_form_values] = useState({});
  const [actionItem, setActionItem] = useState({});
  const [manageModalVisible, setManageModalVisible] = useState(false);
  const [page_limit, setPageLimit] = useState(10);
  const [page_number, setPageNumber] = useState(1);
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [modal_details, set_modal_details] = useState(false);
  const { id } = useParams();
  const { state } = useLocation();
  const [key, setKeys] = useState();

  const handleAdd = () => {
    set_form_values({});
    set_modal_details(true);
  };

  const handleEdit = (item) => {
    set_form_values(item);
    set_modal_details(true);
  };

  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleModalClose = () => {
    setManageModalVisible(false);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };
  const handleKeys = (keys) => {
    setKeys(keys.code);
  };
  const handleSearch = (letter) => {
    if (letter?.length > 3) {
      let keys = [{ key: "getGalleryImageList", loading: true }];
      let variables = {
        search_string: letter,
      };
      dispatch(dynamicRequest(keys, get_all_gallery_image_list, variables));
    }
  };

  const { loading, total_items, items } = useDynamicSelector(
    "getGalleryImageList"
  );
  console.log("items", items);

  const {
    loading: deleteLoading,
    status: deleteStatus,
    error: deleteError,
  } = useDynamicSelector("deleteGalleryImage");
  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({
        type: "error",
        message: deleteError,
      });
    } else if (deleteStatus === "success") {
      showToast({
        type: "success",
        message: `Album ${t("deleted_successfully")}`,
      });
      setDialogVisible(false);
      handleDialogClose();
      get_hostel_details();
      dispatch(dynamicClear("deleteGalleryImage"));
    }
  }, [deleteStatus]);

  const handle_add_hostel = (record) => {
    history.push({
      pathname: `${ROUTES}/${record?.id}`,
      state: record,
    });
  };

  let columns = [
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      sortable: false,
      align: "left",
    },
    {
      title: "Image",
      dataIndex: "image_url",
      key: "image_url",
      sortable: false,
      align: "left",
      render: (image_url) => {
        return (
          <img height="100" style={{objectFit:"cover",width:"50%"}} src={image_url} alt=''/>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "",
      key: "is_active",
      sortable: false,
      align: "left",
      render: (item) => {
        return <Text>{item.is_active ? "Active" : "Inactive"}</Text>;
      },
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      type: "actions",
      width: 200,
      render: (e) => {
        return (
          <>
            <Space space={2}>
              <Pressable
                onPress={() => {
                  handleEdit(e);
                }}
              >
                <AiOutlineEdit color="#06b506" size={20} />
                {/* <CustomizeButton.IconButton icon={<AiOutlineEdit />} /> */}
              </Pressable>
              <Pressable
                onPress={() => {
                  handleDelete(e);
                }}
              >
                <AiOutlineDelete color="red" size={20} />
              </Pressable>
            </Space>
          </>
        );
      },
    },
  ];
  const get_hostel_details = () => {
    let keys = [{ key: "getGalleryImageList", loading: true }];
    let variables = {
      gallery_album_id: id,
    };
    dispatch(dynamicRequest(keys, get_all_gallery_image_list, variables));
  };
  useEffect(() => {
    if (id) get_hostel_details(id);
  }, [page_number, page_limit, id]);

  let header_actions = [
    {
      height: "50px",
      label: "Add Album",
      colorScheme: "primary",
      variant: "outline",
      _text: { color: "green" },
      style: { border: "1px solid green" },
      onPress: handleAdd,
    },
  ];
  const fetchData = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
  };
  const handle_close = () => {
    set_form_values({});
    set_modal_details(false);
  };
  const handle_back = () => {
    history.push(ROUTES.GALLERY_LIST);
  };
  const handle_delete_hostel_details = (values) => {
    let keys = [{ key: "deleteGalleryImage", loading: true }];
    let variables = {
      id: actionItem?.id,
    };
    dispatch(dynamicRequest(keys, mutation_delete_gallery_image, variables));
  };
  return (
    <>
      <Box width="95%" marginLeft="20px" marginRight="20px">
        <HStack position="sticky" top="0px" zIndex="2" bg="white" space={"4px"}>
          <Pressable onPress={handle_back}>
            <Text fontSize="md" fontWeight="bold" py={4}>
              {"Albums"}
            </Text>
          </Pressable>
          <Text fontSize="md" fontWeight="bold" py={4}>
            {` / ${startCase(state?.name)}`}
          </Text>
        </HStack>
        <HStack
          variant="admin_search_component"
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          space={3}
          padding={"10px"}
        >
          {header_actions?.map((headerAction, index) => (
            <Box key={`header-action-${index}`}>
              <ActionButton {...headerAction} />
            </Box>
          ))}
          <Box>
            <SearchBox
              width={300}
              label="Search"
              onSearch={handleSearch}
              onKeyPress={handleKeys}
            />
          </Box>
        </HStack>
        <VStack space={5}>
          <Wrapper>
            <Table
              loading={loading}
              dataSource={items}
              columns={columns}
              pagination={false}
            />
          </Wrapper>
          {!loading && (
            <HStack justifyContent={"flex-end"}>
              <Pagination
                total={total_items}
                page_number={page_number}
                hideOnSinglePage={false}
                onChange={fetchData}
                showTotal={(total, range) =>
                  `Total ${countFormat(total)} items`
                }
                showSizeChanger
              />
            </HStack>
          )}
        </VStack>
      </Box>
      <Modal
        open={modal_details}
        onCancel={handle_close}
        title={
          form_values?.id ? "Update Album Details" : "Create Album Details"
        }
        footer={null}
        width={"900px"}
      >
        <AlbumDetails
          form_values={form_values}
          onCancel={handle_close}
          get_hostel_details={get_hostel_details}
        />
      </Modal>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("error:delete_album")}
        content={t("error:delete_cms_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            onPress: handle_delete_hostel_details,
          },
        ]}
      />
    </>
  );
};
export default AlbumList;
