import { gql } from "@apollo/client";

export const mutation_create_gallery_image = gql`
  mutation createGalleryImage($data: create_gallery_image_input) {
    createGalleryImage(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_update_gallery_image = gql`
  mutation updateGalleryImage($id: String!, $data: update_gallery_image_input) {
    updateGalleryImage(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_delete_gallery_image = gql`
  mutation deleteGalleryImage($id: String!) {
    deleteGalleryImage(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_gallery_image_list = gql`
  query getGalleryImageList(
    $gallery_album_id: String
    $search_string: String
    $page_number: Float
    $page_limit: Float
  ) {
    getGalleryImageList(
      gallery_album_id: $gallery_album_id
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        image_url
        gallery_album_id
        order
        is_active
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_one_gallery_image = gql`
  query getGalleryImage($id: String!) {
    getGalleryImage(id: $id) {
      id
      image_url
      gallery_album_id
      order
      is_active
      error {
        status_code
        message
      }
    }
  }
`;


export const get_all_active_gallery_image_list = gql`
 query getActiveGalleryImageList($gallery_album_id: String) {
  getActiveGalleryImageList(gallery_album_id: $gallery_album_id) {
    items {
      id
      image_url
      gallery_album_id
      order
      is_active
    }
    pagination {
      page_number
      page_limit
      total_count
    }
    error {
      status_code
      message
    }
  }
}
`;