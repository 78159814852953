import { showNotification } from "@helpers/notify";
import { showToast } from "@helpers/toast";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import {
  mutation_create_gallery,
  mutation_update_gallery,
} from "@services/redux/slices/dynamic_entity/graphql/grahpql_cms_gallery";
import {
  mutation_sports_createSystemCmsSportsData,
  mutation_sports_updateSystemCmsSportsData,
} from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_sports_hostal";
import { Button, Checkbox, Form, Input, InputNumber } from "antd";
import { HStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const GalleryDetails = ({ form_values, onCancel, get_sports_hostel_list }) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(true);

  const [form] = Form.useForm();
  const {
    loading: create_loading,
    status: create_status,
    error: create_error,
  } = useDynamicSelector("createGallery");
  const {
    loading: update_loading,
    status: update_status,
    error: update_error,
  } = useDynamicSelector("updateGallery");

  const handle_submit = (values) => {
    console.log("values", values);

    if (form_values?.id) {
      update_gallery(values);
    } else {
      create_gallery(values);
    }
  };

  const create_gallery = (data) => {
    let keys = [{ key: "createGallery", loading: true }];
    let variables = {
      data,
    };
    dispatch(dynamicRequest(keys, mutation_create_gallery, variables));
  };

  const update_gallery = (data) => {
    let keys = [{ key: "updateGallery", loading: true }];
    let variables = {
        id: form_values.id,
        data,
    };
    dispatch(dynamicRequest(keys, mutation_update_gallery, variables));
  };

  useEffect(() => {
    if (update_error) {
      showToast({
        type: "error",
        message: create_error,
      });
      dispatch(dynamicClear("updateGallery"));
    } else if (update_status === "success") {
      showToast({
        type: "success",
        message: `Gallery updated successfully`,
      });
      onCancel();
      get_sports_hostel_list();
      dispatch(dynamicClear("updateGallery"));
    } else if (create_error) {
      showToast({
        type: "error",
        message: create_error,
      });
      dispatch(dynamicClear("createGallery"));
    } else if (create_status === "success") {
      showToast({
        type: "success",
        message: `Gallery created successfully`,
      });
      onCancel();
      get_sports_hostel_list();
      dispatch(dynamicClear("createGallery"));
    }
  }, [update_status, update_error, create_error, create_status]);

  useEffect(() => {
    if (form_values.id) {
      form.setFieldsValue(form_values);
    } else {
      form.resetFields();
    }
  }, [form_values]);
  const handle_close = () => {
    onCancel();
    form.resetFields();
  };

  const onChange = (e) => {
    console.log("checked = ", e.target.checked);
    setChecked(e.target.checked);
  };
  return (
    <div>
      <Form onFinish={handle_submit} form={form} layout="vertical">
        <Form.Item
          name={"name"}
          label={"Name"}
          rules={[
            {
              required: true,
              message: "Name is required",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={"order"}
          label={"Order"}
          rules={[
            {
              required: true,
              message: "Order is required",
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item name={"is_active"} valuePropName="checked" noStyle>
          <Checkbox>Active</Checkbox>
        </Form.Item>

        <HStack justifyContent={"flex-end"} space={"20px"}>
          <Button danger onClick={handle_close}>
            Close
          </Button>
          <Button
            loading={create_loading || update_loading}
            htmlType="submit"
            type="primary"
          >
            {form_values?.id ? "Update" : "Create"}
          </Button>
        </HStack>
      </Form>
    </div>
  );
};

export default GalleryDetails;
