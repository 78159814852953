// import layout1 from "@views/layouts/layout1";
import Layout1 from "@views/layouts/layout1";
import HomePage from "@views/pages/home/home_page";
import ServicePage from "@views/pages/service/servicepage";
import TenderPage from "@views/pages/tender/tender_page";
import AboutUsPage from "@views/pages/about_us/about_us_page";
import ContactUsPage from "@views/pages/contact_us/contact_us_page";
import Sports from "@views/components/sports/sports";
import YouthWalfare from "@views/components/youth_walfare/youth_walfare";
import CompetitionsPage from "@views/pages/competitions_page";
import SportsHostelPage from "@views/pages/sports_hostel_page";
import SchemesPage from "@views/pages/schemes_page";
import InfrastructurePage from "@views/pages/infrastructure_page";
import FAQPage from "@views/pages/faq_page";
import GalleryPage from "@views/pages/gallery_page";
import VigilanceAntiCorruptionPage from "@views/pages/vigilance_anti_corruption_page";
import HighCashIncentivePage from "@views/pages/high_cash_incentive_page";
import RTIQuestionsPage from "@views/pages/rti_questions_page";
import GovernmentOrderPage from "@views/pages/government_order_page";
import AnnouncementsPage from "@views/pages/announcements_page";
import ScreenReaderAccessPage from "@views/pages/screen_reader_access_page";
import admin from "@views/pages/admin";
import ApplicationFormPage from "@views/pages/application_form_page";
import EventsPage from "@views/pages/events_page";
import ContactDetails from "@views/pages/contact_us/contact_details";
import HTMLRenderer from "@views/components/html_render/html_render_content";
import NotificationsPage from "@views/pages/notification_page";
import YouthHostel from "@views/pages/youth_hostel_page";
import LoginScreen from "@views/pages/sign_in";
import cmsList from "@views/pages/cms_input/cms_list";
import cmsDetails from "@views/pages/cms_input/cms_details";
import Redirect from "@views/pages/redirect/redirect";
import PageList from "@views/pages/page/page_list";
import SystemCMS from "@views/pages/cms/system_cms";
import SystemCmsPage from "@views/pages/System_cms_content_page";
import AdminLayout from "@views/layouts/admin_layout";
import SportsHostelList from "@views/pages/cms_sportal_hostal/sports_hostel_list";
import HostelList from "@views/pages/cms_sportal_hostal/hostal_list/hostel_list";
import SportsHostelOfExcellencePage from "@views/pages/sports_hostel_of_excellence";
import CentreOfExcellencePage from "@views/pages/centre_of_excellence";
import PressReleasePage from "@views/pages/page/press_release/press_release";
import GalleryList from "@views/pages/cms_gallery/gallery_list";
import AlbumList from "@views/pages/cms_gallery/album_list/album_list";
import GalleryPageNew from "@views/pages/gallery_page_new";

export const ROUTES = {
  HOME: "/",
  SERVICE: "/services",
  TENDER: "/tenders",
  ABOUT_US: "/about-us",
  CONTACT_US: "/contact-us",
  YOUTH_WALFARE: "/youth-walfare",
  SPORTS: "/sports",
  COMPETITIONS: "/competitions",
  SPORTS_HOSTEL: "/sports-hostel",
  SCHEMES: "/schemes",
  INFRASTRUCTURE: "/Infrastructure",
  FAQS: "/faqs",
  GALLERY: "/gallery",
  GALLERY_OLD: "/gallery-old",
  VIGILANCE_ANTI_CORRUPTION: "/anti-corruption",
  HIGH_CASE_INCENTIVE: "/high-cash-incentive",
  RTI_QUESTIONS: "/rti-questions",
  GOVERNMENT_ORDER: "/government-orders",
  ANNOUNCEMENTS_PAGE: "/announcements",
  SCREEN_READER_ACCESS: "/screen-reader-access",
  TEST: "/test",
  APPLICATION_FORM: "/budget-expenditure",
  EVENTS: "/events",
  RENDER: "/render",
  CONTACT_DETAILS: "/contact-details",
  NOTIFICATIONS: "/notifications",
  YOUTH_HOSTEL: "/youth_hostel",
  LOGIN: "/admin",
  EMPTY: "/",
  PAGE: "/page",
  SYSTEM_CMS: "/admin/system-cms",
  CMS_LIST: "/admin/cms_list",
  CMS_DETAILS: "/admin/cms_details",
  SYSTEM_CMS_VIEW: "/system_cms_view",
  ACCESS_RIGHT: "/admin/access_rights",
  SPORTS_HOSTEL_LIST: "/admin/sports_hostel",
  HOSTEL_LIST: "/admin/sports_hostel/details",
  SPORTS_HOSTEL_OF_EXCELLENCE: "/sports-hostel-of-excellence",
  CENTRE_OF_EXCELLENCE: "/centre-of-excellence",
  PRESS_RELEASE:"/press-release",
  GALLERY_LIST: "/admin/gallery_list",
  ALBUM_LIST: "/admin/gallery_list/details",


};
let myRoutes = [
  {
    path: ROUTES.HOME,
    exact: true,
    page_key: "home",
    component: HomePage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.SERVICE,
    exact: true,
    page_key: "services",
    component: ServicePage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.TENDER,
    exact: true,
    page_key: "tender",
    component: TenderPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.ABOUT_US,
    exact: true,
    page_key: "about-us",
    component: AboutUsPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.CONTACT_US,
    exact: true,
    page_key: "contact-us",
    component: ContactUsPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.CONTACT_DETAILS,
    exact: true,
    page_key: "contact-details",
    component: ContactDetails,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.YOUTH_WALFARE,
    exact: true,
    page_key: "youth-walfare",
    component: YouthWalfare,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.SPORTS,
    exact: true,
    page_key: "sports",
    component: Sports,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.COMPETITIONS,
    exact: true,
    page_key: "competitions",
    component: CompetitionsPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.SPORTS_HOSTEL,
    exact: true,
    page_key: "sports-hostel",
    component: SportsHostelPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.SCHEMES,
    exact: true,
    page_key: "schemes",
    component: SchemesPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.INFRASTRUCTURE,
    exact: true,
    page_key: "infrastructure",
    component: InfrastructurePage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.FAQS,
    exact: true,
    page_key: "faqs",
    component: FAQPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.PRESS_RELEASE,
    exact: true,
    page_key: "press_release",
    component: PressReleasePage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.GALLERY_OLD,
    exact: true,
    page_key: "gallery",
    component: GalleryPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.GALLERY,
    exact: true,
    page_key: "gallery",
    component: GalleryPageNew,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.VIGILANCE_ANTI_CORRUPTION,
    exact: true,
    page_key: "vigilance-anti-corruption",
    component: VigilanceAntiCorruptionPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.HIGH_CASE_INCENTIVE,
    exact: true,
    page_key: "high-cash-incentive",
    component: HighCashIncentivePage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.RTI_QUESTIONS,
    exact: true,
    page_key: "RTI-questions",
    component: RTIQuestionsPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.GOVERNMENT_ORDER,
    exact: true,
    page_key: "government-order",
    component: GovernmentOrderPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.ANNOUNCEMENTS_PAGE,
    exact: true,
    page_key: "announcement",
    component: AnnouncementsPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.SCREEN_READER_ACCESS,
    exact: true,
    page_key: "screen-reader-access",
    component: ScreenReaderAccessPage,
    authenticate: false,
    layout: Layout1,
  },

  {
    path: ROUTES.APPLICATION_FORM,
    exact: true,
    page_key: "budget-expenditure",
    component: ApplicationFormPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.EVENTS,
    exact: true,
    page_key: "events",
    component: EventsPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.NOTIFICATIONS,
    exact: true,
    page_key: "events",
    component: NotificationsPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.RENDER,
    exact: true,
    page_key: "render",
    component: HTMLRenderer,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.YOUTH_HOSTEL,
    exact: true,
    page_key: "Youth_hostel",
    component: YouthHostel,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.LOGIN,
    exact: true,
    page_key: "Login",
    component: LoginScreen,
    // layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.CMS_LIST}`,
    exact: true,
    page_key: "cms-list",
    component: cmsList,
    layout: AdminLayout,
    authenticate: true,
  },
  {
    path: `${ROUTES.CMS_DETAILS}/:id?`,
    exact: true,
    page_key: "cms-details",
    component: cmsDetails,
    layout: AdminLayout,
    authenticate: true,
  },
  {
    path: `${ROUTES.SPORTS_HOSTEL_LIST}/:id?`,
    exact: true,
    page_key: "sports-hostel",
    component: SportsHostelList,
    layout: AdminLayout,
    authenticate: true,
  },
  {
    path: `${ROUTES.HOSTEL_LIST}/:id?`,
    exact: true,
    page_key: "sports-hostel-details",
    component: HostelList,
    layout: AdminLayout,
    authenticate: true,
  },
  {
    path: ROUTES.EMPTY,
    exact: true,
    page_key: "redirect",
    component: Redirect,
    authenticate: false,
  },
  {
    path: ROUTES.PAGE,
    exact: true,
    page_key: "page",
    component: PageList,
    layout: AdminLayout,
    authenticate: true,
  },
  {
    path: ROUTES.SYSTEM_CMS,
    exact: true,
    page_key: "system-cms",
    component: SystemCMS,
    // layout: AdminLayout,
    authenticate: true,
  },
  {
    path: `${ROUTES.SYSTEM_CMS}/:id?`,
    exact: true,
    page_key: "system-cms-details",
    component: SystemCmsPage,
    // layout: AdminLayout,
    // layout: SystemCMS,
    authenticate: true,
  },
  {
    path: `${ROUTES.ACCESS_RIGHT}`,
    exact: true,
    page_key: "access-right",
    component: cmsList,
    layout: AdminLayout,
    // layout: SystemCMS,
    authenticate: true,
  },
  {
    path: ROUTES.SPORTS_HOSTEL_OF_EXCELLENCE,
    exact: true,
    page_key: "sports-hostel-of-excellence",
    component: SportsHostelOfExcellencePage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.CENTRE_OF_EXCELLENCE,
    exact: true,
    page_key: "centre-of-excellence",
    component: CentreOfExcellencePage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: `${ROUTES.GALLERY_LIST}/:id?`,
    exact: true,
    page_key: "gallery-list",
    component: GalleryList,
    layout: AdminLayout,
    authenticate: true,
  },
  {
    path: `${ROUTES.ALBUM_LIST}/:id?`,
    exact: true,
    page_key: "album-details",
    component: AlbumList,
    layout: AdminLayout,
    authenticate: true,
  },
];
export default myRoutes;
