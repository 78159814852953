import React, { useEffect } from "react";
import { Box, Center, HStack, Pressable } from "native-base";
import { IconList, language_list } from "@helpers/constants.js";
import SwitchSelector from "react-switch-selector";
import { useTranslation } from "react-i18next";
import { Options, Icons } from "@helpers/constants.js";
import { changeLanguage } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { decreaseFontSize, fontSizeSelector, increaseFontSize, setDefaultFontSize } from "@services/redux";
import FontIncreaseIcon from "@assets/images/menu/font-increase.png";
import FontDecreaseIcon from "@assets/images/menu/font-decrease.png";
import FontNormalIcon from "@assets/images/menu/font-normal.png";
import { MdOutlineVibration } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { retrieveItem, storeItem } from "@helpers/storage";
import { useLocation } from "react-router-dom";

const Header = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory()
  const { scale, font_size_change } = useSelector(fontSizeSelector);
  let language = retrieveItem("language")
  const { pathname } = useLocation()
  const initialSelectedIndex = Options(t).findIndex(
    ({ value }) => value === language?.key
  );
  const onChange = (newValue, hj) => {
    let language = language_list?.find((list) => list?.key === newValue)
    storeItem("language", language)
    changeLanguage(newValue);
    window.location.replace(pathname)
  };

  const FontIncrease = () => {
    dispatch(increaseFontSize())
  };
  const FontDecrease = () => {
    dispatch(decreaseFontSize())

  };
  const FontDefault = () => {
    dispatch(setDefaultFontSize())

  };

  const handle_redirect = () => {
    history.push(ROUTES.SCREEN_READER_ACCESS)
  }

  useEffect(() => {
    changeLanguage(language?.key);
  }, [])
  return (
    <Box variant="bannerBox">
      <HStack
        // space={{ xs: "5rem" }}
        justifyContent={"space-between"}
        alignItems="center"
        ml={{ lg: "35px", xs: "15px" }}
        mr={{ lg: "46px", xs: "15px" }}
      >
        <Box>
          <HStack space={2} alignItems="center">
            {Icons?.map((e) => {
              return (
                <Box variant="FooterIconBox">
                  {/* <Center alignItems={"center"}> */}
                  <span className="footer_icon_list">
                    {/* <Center> */}
                    {e.icon}
                    {/* </Center> */}
                  </span>
                  {/* </Center> */}
                </Box>
              );
            })}
          </HStack>
        </Box>
        <Box>
          <HStack space={2} alignItems="center">
            {/* {IconList?.map((e) => { */}
            {/* return ( */}
            <Box variant="iconContainer">
              {/* <Center> */}
              {/* <span className="header-banner"> */}
              <Center>
                <Pressable onPress={handle_redirect}>
                  <span style={{ textDecoration: "none", color: "black" }}>
                    <MdOutlineVibration size={15} className="vibrate-icon" />
                  </span>
                </Pressable>
              </Center>
              {/* </span> */}
              {/* </Center> */}
            </Box>
            <Box variant="iconContainer">
              {/* <Center> */}
              {/* <span className="header-banner"> */}
              <Center>
                <Pressable
                  onPress={
                    font_size_change >= -2
                      ? () => {
                        FontDecrease()
                      }
                      : ""
                  }
                >
                  <img
                    src={FontDecreaseIcon}
                    alt={"FontDecrease"}
                    className={"icons-list"}
                  />
                </Pressable>
              </Center>
              {/* </span> */}
              {/* </Center> */}
            </Box>
            <Box variant="iconContainer">
              {/* <Center> */}
              {/* <span className="header-banner"> */}
              <Center>
                <Pressable
                  onPress={FontDefault}
                >
                  <img
                    src={FontNormalIcon}
                    alt={"FontNormal"}
                    className={"icons-list"}
                  />
                </Pressable>
              </Center>
              {/* </span> */}
              {/* </Center> */}
            </Box>
            <Box variant="iconContainer">
              {/* <Center> */}
              {/* <span className="header-banner"> */}
              <Center>
                <Pressable
                  onPress={
                    font_size_change <= 2
                      ? () => {
                        FontIncrease();
                      }
                      : ""
                  }
                >
                  <img
                    src={FontIncreaseIcon}
                    alt={"FontIncrease"}
                    className={"icons-list"}
                  />
                </Pressable>
              </Center>
              {/* </span> */}
              {/* </Center> */}
            </Box>
            {/* // ); // })} */}
            <Box variant="switchBox">
              <SwitchSelector
                className="switch-button"
                onChange={onChange}
                options={Options(t)}
                // initialSelectedIndex={initialSelectedIndex}
                initialSelectedIndex={initialSelectedIndex}
                wrapperBorderRadius={10}
                optionBorderRadius={10}
                fontSize="7px"
                
              />
            </Box>
          </HStack>
        </Box>
      </HStack>
    </Box>
  );
};
export default Header;
